
import {defineComponent, reactive, ref, toRaw} from 'vue';
import {JsonParser, ParseContext} from "bao-json";
import {json2JavaBean} from "@/util/render";
import Codemirror from "codemirror-editor-vue3";
import {DemoJson1} from "@/db/demodata";
import useCmConfig from "@/composables/useCmConfig";
// import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import {Option, Options} from "@/type";


export default defineComponent({
  components: {
    Codemirror,
    // QuestionCircleOutlined,
  },
  setup() {
    const activeKey = ref('1');
    const tabPosition = ref('right');
    const result = ref('');
    const formState = reactive({
      json: DemoJson1,
      tpl: '',
      // isJavadocComment: '1',
      options: [Option.JavadocComment, Option.ValueAsCommentIfAbsent],
    });

    const optionsConfig = [
      // 貌似 字段名不用 label 可能和 slot 的名一样, 触发未知bug, 自定义 template 不生效咯, 估计是将 label 字段做了更高的优先级
      {value: Option.JavadocComment, title: 'Javadoc注释', tooltip: '注释是否统一成Javadoc格式的注释'},
      {value: Option.ValueAsCommentIfAbsent, title: '值候补注释', tooltip: '没有注释时, 值候补作为注释'},
      // {value: Json2JavaBeanOptions.Value2Mock, label: '值作为Mock'},
    ]

    /*// 拿到默认 template
    ipcRenderWrap.send(IpcChannel.getTplContent, (e, a) => {
      formState.tpl = a;
    });*/

    const parseError = ref(undefined);

    function getResult() {
      const parser = new JsonParser(formState.json);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let context: ParseContext = undefined;
      try {
        context = parser.parse();
      } catch (e) {
        parseError.value = e.message;
        // activeKey 强行置回到 1
        activeKey.value = '1';
        throw e;
      }
      console.log("----> context: ", context)
      // result.value = ejs.render(formState.tpl, {context});
      result.value = json2JavaBean(context, new Options(formState.options));
    }

    function onTabChange(currKey) {
      if (currKey == '2') {
        getResult();
      }
    }

    const onSubmit = () => {
      console.log('submit!', toRaw(formState));
      getResult();
      activeKey.value = '2';
    };

    const {cmRef: jsonCmRef, cmOptions} = useCmConfig({
      mode: 'application/json',
      theme: 'xq-light',
    });
    const {cmOptions: jbCmOptions} = useCmConfig({
      mode: 'text/x-java',
      autofocus: true,
    });

    const onJsonChange = (evt) => {
      // console.log('onJsonChange', evt);
      // 每次修改, 都假设正确了, 将上次的 error 清空
      parseError.value = undefined;
    }

    // -- 表单选项 展开-收起 --
    const toggleSearchStatus = ref(false);

    function handleToggleSearch() {
      toggleSearchStatus.value = !toggleSearchStatus.value;
    }

    return {
      activeKey,
      tabPosition,
      labelCol: {span: 4},
      wrapperCol: {span: 20},
      formState,
      result,
      parseError,
      onSubmit,
      onTabChange,
      jsonCmRef,
      cmOptions,
      jbCmOptions,
      onJsonChange,
      toggleSearchStatus,
      handleToggleSearch,
      optionsConfig,
    };
  },
});
